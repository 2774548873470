<template>
  <div class="user-page container-fluid">

    <form @submit.prevent="doUserPasswordEdit">

      <div class="buttons">
        <div class="float-end">
          <button class="button-confirm" type="submit">
            <span class="fa fa-check"></span>
          </button>
        </div>
        <router-link :to="{path: '/user/edit'}" class="button-back">
          <span class="fa fa-arrow-left"></span>
        </router-link>
      </div>

      <div class="col-12 col-lg-4 offset-lg-4 form-group">
        <label for="passwordOld" class="form-label">Old password<span class="f-required">*</span></label>
        <input
            v-model="passwordOld"
            :class="{'is-invalid': errors?.passwordOld}"
            type="password"
            class="form-control"
            id="passwordOld"
            autocomplete="false"
            placeholder="********" />
        <div class="invalid-feedback">{{ errors?.passwordOld }}</div>
      </div>

      <div class="col-12 col-lg-4 offset-lg-4 form-group">
        <label for="password" class="form-label">New password<span class="f-required">*</span></label>
        <input
            v-model="password"
            :class="{'is-invalid': errors?.password}"
            type="password"
            class="form-control"
            id="password"
            autocomplete="false"
            placeholder="********" />
        <div class="invalid-feedback">{{ errors?.password }}</div>
      </div>

    </form>

  </div>
</template>

<script>

export default {

  data: () => ({
    password: null,
    passwordOld: null
  }),

  async mounted() {

    if (!await this.checkUser())
      return;
  },

  methods: {

    async doUserPasswordEdit() {

      let apiHolder = await this.sendApiPost('api/user/password/edit', {
        password: this.password,
        passwordOld: this.passwordOld
      });

      if (!apiHolder.isSuccess())
        return;

      await this.redirect('/user/password/edit', 'Password has been successfully updated', 'success');
    }
  }
}
</script>

<style scoped>

.buttons {
  padding-top: 15px;
  padding-bottom: 20px;
}

</style>